<script setup lang="ts">
import { type Ref, ref } from 'vue'
import { useAuthStore } from '@/store/useAuthStore'
import { storeToRefs } from 'pinia'
import { useTheme } from 'vuetify'
import { watch } from 'vue'
import { SofaEnvBanner } from '@ccwm/sofa-lib-vue-components'
import { ROLE_CONFIG } from './models/roles.model'

const authenticationStore = useAuthStore()
const env: Ref<'dev' | 'val' | 'rec' | 'hml' | 'prod'> = ref(import.meta.env.VITE_ENV)
const { userRole } = storeToRefs(authenticationStore)

const theme = useTheme()

watch(userRole, () => {
  // const chosenIndexRole = ROLE_CONFIG.findIndex((item) => item.role === userRole.value)
  theme.global.name.value = ROLE_CONFIG[2].theme
  // theme.global.name.value = chosenIndexRole !== -1 ? ROLE_CONFIG[chosenIndexRole].theme : ROLE_CONFIG[0].theme
})
</script>
<template>
  <div>
    <router-view />
    <SofaEnvBanner
      :env="env"
      position="bottom-right"
    />
  </div>
</template>
