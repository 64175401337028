import { type ThemeDefinition } from 'vuetify'

export const myCustomCreditorTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: 'rgb(80,95,50)',
    primaryFade: '#d5dbc8',
    secondary: 'rgb(245,231,211)',
    textColor: 'rgb(80,95,50)',
    white: '#f4f4f4',
    black: '#1a1a1a',

    new: '#B00020',
    processing: '#FB8C00',
    completed: '#4CAF50',
    validate: '#006F2E',
    refuse: '#B91200',

    validateLight: '#CBE9DA',
    refuseLight: '#F7CFCB',
  },
}

export const myCustomRecovererTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: 'rgb(10,28,137)',
    primaryFade: '#D9D9F1',
    secondary: 'rgb(255,91,0)',
    textColor: '#f4f4f4',
    white: '#f4f4f4',
    black: '#1a1a1a',

    new: '#B00020',
    processing: '#FB8C00',
    completed: '#4CAF50',
    validate: '#006F2E',
    refuse: '#B91200',

    validateLight: '#CBE9DA',
    refuseLight: '#F7CFCB',
  },
}

export const myCustomOperatorTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#E03136',
    primaryFade: '#ebdadf',
    secondary: '#1a1a1a',
    textColor: '#f4f4f4',
    white: '#f4f4f4',
    black: '#201A1B',

    new: '#B00020',
    processing: '#FB8C00',
    completed: '#4CAF50',
    validate: '#006F2E',
    refuse: '#B91200',

    validateLight: '#CBE9DA',
    refuseLight: '#F7CFCB',
  },
}
