import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/store/useAuthStore'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    meta: { isRequiredAuth: false },
    component: () => import('@/views/LoginScreen.vue'),
  },
  {
    path: '/authorize',
    name: 'Authorize',
    meta: { isRequiredAuth: false },
    component: () => import('@/views/AuthorizeView.vue'),
  },
  {
    path: '/mandataire',
    name: 'Creditor',
    redirect: { name: 'CreditorHome' },
    component: () => import('@/views/CreditorView.vue'),
    children: [
      {
        path: 'accueil',
        name: 'CreditorHome',
        component: () => import('@/views/CreditorHome.vue'),
      },
      {
        path: 'factures',
        name: 'CreditorInvoices',
        component: () => import('@/views/CreditorInvoices.vue'),
        children: [
          {
            path: '',
            name: 'CreditorInvoicesTable',
            component: () => import('@/views/CreditorInvoicesTable.vue'),
          },
          {
            path: ':id',
            name: 'CreditorInvoicesTableWithId',
            component: () => import('@/views/CreditorInvoicesDetails.vue'),
          },
          {
            path: 'creer',
            name: 'CreditorInvoicesForm',
            component: () => import('@/views/CreditorInvoicesForm.vue'),
          },
          {
            path: 'recapitulatif',
            name: 'CreditorInvoicesSummary',
            component: () => import('@/views/CreditorInvoicesSummary.vue'),
          },
        ],
      },
      {
        path: 'notifications',
        name: 'CreditorNotifications',
        component: () => import('@/views/CreditorNotifications.vue'),
      },
      {
        path: 'messagerie',
        name: 'CreditorMessaging',
        component: () => import('@/views/CreditorNotifications.vue'),
      },
    ],
    meta: {
      isRequiredAuth: true,
    },
  },
  {
    path: '/prestataire',
    name: 'Recoverer',
    redirect: { name: 'RecovererHome' },
    component: () => import('@/views/RecovererView.vue'),
    children: [
      {
        path: 'accueil',
        name: 'RecovererHome',
        component: () => import('@/views/RecovererHome.vue'),
      },
      {
        path: 'notifications',
        name: 'RecovererNotifications',
        component: () => import('@/views/RecovererNotifications.vue'),
      },
      {
        path: 'factures',
        name: 'RecovererInvoices',
        component: () => import('@/views/RecovererInvoices.vue'),
        children: [
          {
            path: '',
            name: 'RecovererInvoicesTable',
            component: () => import('@/views/RecovererInvoicesTable.vue'),
          },
          {
            path: ':id',
            name: 'RecovererInvoicesTableWithId',
            component: () => import('@/views/RecovererInvoicesDetails.vue'),
          },
        ],
      },
      {
        path: 'messagerie',
        name: 'RecovererMessaging',
        component: () => import('@/views/RecovererInvoices.vue'),
      },
    ],
    meta: {
      isRequiredAuth: true,
    },
  },
  {
    path: '/back-office',
    name: 'Operator',
    redirect: { name: 'OperatorHome' },
    component: () => import('@/views/BackOfficeView.vue'),
    children: [
      {
        path: 'accueil',
        name: 'OperatorHome',
        component: () => import('@/views/BackOfficeHome.vue'),
      },
      {
        path: 'factures',
        name: 'OperatorInvoices',
        component: () => import('@/views/BackOfficeInvoices.vue'),

        children: [
          {
            path: '',
            name: 'OperatorInvoicesTable',
            component: () => import('@/views/BackOfficeInvoicesTable.vue'),
          },
          {
            path: ':id',
            name: 'OperatorInvoicesTableWithId',
            component: () => import('@/views/BackOfficeInvoicesDetails.vue'),
          },
        ],
      },
      {
        path: 'configuration',
        name: 'OperatorSettings',
        component: () => import('@/views/BackOfficeSettings.vue'),
      },
    ],
    meta: {
      isRequiredAuth: true,
    },
  },
  {
    path: '/erreur/:code?',
    name: 'Error',
    component: () => import('@/views/ErrorView.vue'),
    props: true,
  },
  {
    // https://router.vuejs.org/guide/essentials/dynamic-matching.html#Catch-all-404-Not-found-Route
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/erreur/404',
  },
]
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  linkActiveClass: 'navigation-link-active',
})

router.beforeEach((to, from, next) => {
  //try store solution
  const authStore = useAuthStore()
  if (!to.meta.isRequiredAuth) next()

  if (sessionStorage.access_token && Date.now() < sessionStorage.access_token_expiration) {
    if (!authStore.isConnected) authStore.init()
    next()
  } else {
    next('/')
  }
})

// Gestion d'erreur minimale
router.onError(() => {
  router.push('/erreur')
})

export default router
