import { type RouteRecordName } from 'vue-router'

export type RoleType = 'CREDITOR' | 'RECOVERER' | 'OPERATOR'
export type CustomThemes = 'myCustomCreditorTheme' | 'myCustomRecovererTheme' | 'myCustomOperatorTheme'

export type RoleConfigType = {
  role: RoleType
  routeName: RouteRecordName
  theme: CustomThemes
}

export const ROLE_CONFIG: RoleConfigType[] = [
  {
    role: 'CREDITOR',
    routeName: 'Creditor',
    theme: 'myCustomCreditorTheme',
  },
  {
    role: 'RECOVERER',
    routeName: 'Recoverer',
    theme: 'myCustomRecovererTheme',
  },
  {
    role: 'OPERATOR',
    routeName: 'Operator',
    theme: 'myCustomOperatorTheme',
  },
]
