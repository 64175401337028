/* eslint-disable no-console */
import { defineStore } from 'pinia'
import { type RoleType } from '@/models/roles.model'
import { type UserInfo } from '@/models/InterfaceUserInfo'
import type OauthService from '@/services/OauthService'

interface UseAuthStoreInterface {
  userInfo: UserInfo
  loginError: string
  isLoading: boolean
  userRole: RoleType | null
  userName: string | null
  isConnected: boolean
  oauthService: OauthService
}

export const useAuthStore = defineStore({
  id: 'authStore',
  state: (): UseAuthStoreInterface => {
    return {
      userInfo: {} as UserInfo,
      loginError: '',
      isLoading: false,
      userRole: null,
      userName: null,
      isConnected: false,
      oauthService: null as unknown as OauthService,
    }
  },
  getters: {},
  actions: {
    init() {
      this.isConnected = true
    },
    async logout() {
      let redirectUri = import.meta.env.VITE_GOLEM_WEB_URL
      try {
        redirectUri = await this.oauthService.revoke()
      } catch (e) {
        console.error(e)
      } finally {
        window.localStorage.clear()
        window.sessionStorage.clear()
        this.userInfo = {} as UserInfo
        this.loginError = ''
        this.userRole = null
        this.userName = null
        this.isConnected = false
        this.oauthService = null as unknown as OauthService
        window.location.href = redirectUri
      }
    },

    setUser(userRole: RoleType, userName: string) {
      this.userRole = userRole
      this.userName = userName
    },
  },
})
