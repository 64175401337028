import 'vuetify/styles'
import '@/assets/css/global.css'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { createApp, h } from 'vue'
import { createPinia } from 'pinia'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { ROLE_CONFIG } from './models/roles.model'
import {
  myCustomCreditorTheme,
  myCustomRecovererTheme,
  myCustomOperatorTheme,
} from './utils/statement/vuetify-theme-definition'
import {
  mdiClose,
  mdiCommentCheck,
  mdiCommentProcessing,
  mdiCommentRemove,
  mdiReceiptTextArrowLeft,
  mdiReceiptTextArrowRight,
  mdiReceiptTextCheck,
  mdiReceiptTextClock,
  mdiClockTimeEightOutline,
  mdiReceiptTextOutline,
  mdiMessageReplyTextOutline,
  mdiFileDocumentOutline,
} from '@mdi/js'

import App from './App.vue'
import router from './router'

const themeChecker = () => {
  // const userRole = sessionStorage.getItem('role')
  // if (!userRole) {
  //   return ROLE_CONFIG[0].theme
  // } else {
  //   return ROLE_CONFIG.find((item) => item.role === userRole)?.theme
  // }
  return ROLE_CONFIG[2].theme
}

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      receiptTextCheck: mdiReceiptTextCheck,
      receiptTextClock: mdiReceiptTextClock,
      close: mdiClose,
      receiptTextArrowLeft: mdiReceiptTextArrowLeft,
      receiptTextArrowRight: mdiReceiptTextArrowRight,
      commentCheck: mdiCommentCheck,
      commentProcessing: mdiCommentProcessing,
      commentRemove: mdiCommentRemove,
      bottomBarActivity: mdiClockTimeEightOutline,
      bottomBarDetails: mdiReceiptTextOutline,
      bottomBarRecoveryPlan: mdiMessageReplyTextOutline,
      bottomBarDocument: mdiFileDocumentOutline,
    },
    sets: {
      mdi,
    },
  },
  locale: {
    messages: {
      loading: 'Chargement ... ',
    },
  },
  theme: {
    defaultTheme: themeChecker(),
    themes: {
      myCustomCreditorTheme,
      myCustomRecovererTheme,
      myCustomOperatorTheme,
    },
    variations: {
      colors: ['primary', 'primaryFade', 'secondary', 'secondaryFade', 'black'],
      lighten: 6,
      darken: 6,
    },
  },
})

createApp({
  setup() {},
  render: () => h(App),
})
  .use(createPinia())
  .use(router)
  .use(vuetify)
  .mount('#app')
